import { Injectable, inject } from '@angular/core'
import { LoadingStatuses } from '@navix/shared/loading'
import { getRouterSelectors } from '@ngrx/router-store'
import { Store, select } from '@ngrx/store'
import { firstValueFrom } from 'rxjs'
import { DataFilters } from '../domain/data-filters.model'
import { AsyncOperations } from '../domain/document-loading.model'
import { documentsActions } from './documents.actions'
import * as DocumentsSelectors from './documents.selectors'

const { selectRouteParams } = getRouterSelectors()

@Injectable()
export class DocumentsFacade {
  private readonly store = inject(Store)

  loading$ = this.store.pipe(select(DocumentsSelectors.selectLoading))
  allDocuments$ = this.store.pipe(select(DocumentsSelectors.selectAllDocuments))
  selectedDocument$ = this.store.pipe(select(DocumentsSelectors.selectEntity))
  selectedDocument = this.store.selectSignal(DocumentsSelectors.selectEntity)
  filters$ = this.store.pipe(select(DocumentsSelectors.selecFilters))
  filters = this.store.selectSignal(DocumentsSelectors.selecFilters)
  totalCount$ = this.store.pipe(select(DocumentsSelectors.selectTotalCount))
  routeParams$ = this.store.pipe(select(selectRouteParams))
  documentStatusesCount$ = this.store.pipe(
    select(DocumentsSelectors.selectDocumentStatusesCount)
  )

  loadDocuments(filters: DataFilters) {
    this.store.dispatch(documentsActions.loadDocuments({ filters }))
  }

  loadSubmission(id: string) {
    this.store.dispatch(documentsActions.loadSubmission({ id }))
  }

  loadDocumentsCount() {
    this.store.dispatch(documentsActions.loadDocumentsStatusesCount())
  }

  deleteDocument(id: string) {
    this.store.dispatch(documentsActions.deleteDocument({ id }))
  }

  awaitDocument(id: string) {
    this.store.dispatch(documentsActions.awaitingDocument({ id }))
  }

  setSelectedId(selectedId: string) {
    this.store.dispatch(documentsActions.setSelectedId({ selectedId }))
  }

  endLoading(operation: AsyncOperations) {
    this.store.dispatch(
      documentsActions.setLoading({
        operation,
        loading: LoadingStatuses.NotStarted
      })
    )
  }

  async updateListFilters(filters: DataFilters) {
    const currentFilters = await firstValueFrom(this.filters$)
    const newFilters = { ...currentFilters, ...(filters ?? {}) }
    this.store.dispatch(
      documentsActions.setListFilters({ filters: newFilters })
    )
  }

  patchListFilters(filters: DataFilters) {
    this.store.dispatch(documentsActions.patchListFilters({ filters }))
  }
}
